import { useEffect } from 'react'

import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import { Image } from 'components/UI/Image'
import Modal from 'components/UI/Modal/Modal'

import { getUserId, getUserRole } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { partyPopperEmoji } from 'utils/emojis'
import useConfetti from 'utils/hooks/useConfetti'
import { MIXPANEL_EVENTS, Mixpanel } from 'utils/integrations/scripts/mixpanel'

export function WelcomeModal({
  state,
  handleClose,
  company,
  openUpdateCompanyPhoneModal,
}) {
  const isLaptopUp = useMediaQuery((theme) => theme.breakpoints.up('laptop'))
  const { throwConfetti } = useConfetti()

  const handleStartTour = () => {
    Mixpanel.track(MIXPANEL_EVENTS.INTERACTIVE_TOUR_STARTED, {
      user_id: getUserId(),
      company_id: getCompanyId(),
      user_role: getUserRole(),
    })
    handleClose()
    window.location.href = 'https://app.storylane.io/share/zte4ufakmei0'
  }

  const {
    onboarding: { workers_number: workersNumber },
  } = company

  const hasTwentyorMoreWorkers = workersNumber !== '1-19'

  useEffect(() => {
    throwConfetti({ origin: { x: 0.5, y: 0.9 }, particleCount: 150 })
    throwConfetti({ origin: { x: 0.3, y: 0.4 }, particleCount: 150 })
    throwConfetti({ origin: { x: 0.7, y: 0.4 }, particleCount: 150 })
  }, [throwConfetti])

  const onClose = () => {
    if (hasTwentyorMoreWorkers) {
      openUpdateCompanyPhoneModal()
    }

    handleClose()
  }

  return (
    <Modal
      open={state.open}
      onCancel={onClose}
      hideFooter
      paperSx={({ breakpoints }) => ({
        maxWidth: '49rem',
        [breakpoints.up('laptop')]: {
          maxHeigth: '23.127rem',
        },
      })}
      contentWrapperSx={{
        overflow: 'hidden',
      }}
    >
      <ButtonBase
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          cursor: 'pointer',
          color: theme.palette.black.main,
          right: theme.spacing(-1),
          top: theme.spacing(0),
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </ButtonBase>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            mobile: '1fr',
            laptop: '1fr  3fr',
          },
        }}
      >
        {isLaptopUp ? (
          <Box>
            <Box
              sx={(theme) => ({
                width: '35rem',
                height: '35rem',
                borderRadius: '100%',
                position: 'absolute',
                left: '-21rem',
                top: '-7rem',
                backgroundColor: theme.palette.complementary1.main,
              })}
            />
            <Box
              sx={(theme) => ({
                position: 'relative',
                height: '100%',
                width: '18rem',
                display: 'flex',
                alignItems: 'center',
                marginLeft: theme.spacing(-4),
              })}
            >
              <Image
                src="68ebf52f-1afc-4fec-1f84-0be8f9640100"
                height={330}
                width={290}
              />
            </Box>
          </Box>
        ) : null}
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('laptop')]: {
              marginLeft: theme.spacing(4),
              maxWidth: '25.875rem',
            },
          })}
        >
          <Typography
            variant="h2"
            sx={(theme) => ({
              marginBottom: theme.spacing(2),
            })}
          >
            ¡Dile hola a Aleluya! <Emoji code={partyPopperEmoji} />
          </Typography>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
              gap: theme.spacing(2),
            })}
          >
            <Typography sx={{ fontWeight: 'bold' }} component="span">
              Tienes 15 días para explorar el plan de Nómina Holística GRATIS
            </Typography>
            . El primer paso para enamorarte de todo lo que podemos hacer por
            ti, es{' '}
            <Typography sx={{ fontWeight: 'bold' }} component="span">
              recorrer nuestro tour interactivo
            </Typography>
            .
          </Typography>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({
              marginBottom: theme.spacing(6),
            })}
          >
            PD: ¡para una mejor experiencia, ingresa desde tu computadora!
          </Typography>
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(2),
              [theme.breakpoints.down('tablet')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Button onClick={handleStartTour}>Comencemos</Button>
            <Button variant="outlined" onClick={onClose}>
              Cerrar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
