import mixpanel from 'mixpanel-browser'

import { isOrganizer, isWorker } from 'utils/auth'
import { isDev } from 'utils/general'

if (!isDev && !isOrganizer() && process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: isDev,
    record_sessions_percent: isDev ? 100 : 10,
    record_block_selector: 'none',
    record_mask_text_selector: 'none',
    track_pageview: 'full-url',
    persistence: 'localStorage',
    secure_cookie: !isDev,
  })
}

export const Mixpanel = {
  instance: mixpanel,
  identify: (userId) => {
    try {
      if (!isDev && !isOrganizer()) {
        mixpanel.identify(userId)
      }
    } catch (error) {
      // We don't want to break the app if Mixpanel fails
    }
  },
  track: (event, properties, bypassRoleValidation = false) => {
    try {
      if (!isDev && (bypassRoleValidation || !isOrganizer())) {
        mixpanel.track(event, properties)
      }
    } catch (error) {
      // We don't want to break the app if Mixpanel fails
    }
  },
  trackWorker: (event, properties) => {
    try {
      if (!isDev && isWorker() && !isOrganizer()) {
        mixpanel.track(event, properties)
      }
    } catch (error) {
      // We don't want to break the app if Mixpanel fails
    }
  },
  reset: () => {
    try {
      if (!isDev && !isOrganizer()) {
        mixpanel.reset()
      }
    } catch (error) {
      // We don't want to break the app if Mixpanel fails
    }
  },
}

export const MIXPANEL_EVENTS = {
  SIGNED_UP_WITH_GOOGLE: 'signed_up_with_google',
  SIGNED_IN_WITH_GOOGLE: 'signed_in_with_google',
  SIGNED_UP: 'signed_up',
  SIGN_IN_V2: 'sign_in_v2',
  SIGNUP_COMPANY: 'signup_company',
  SIGNUP_AREA: 'signup_area',
  SIGNUP_COMPLETED: 'signup_completed',
  INTERACTIVE_TOUR_STARTED: 'interactive_tour_started',
  INTERACTIVE_TOUR_COMPLETED: 'interactive_tour_completed',
  COMPANY_CLAIM_STARTED: 'company_claim_started',
  CONFIGURE_INTEGRATION: 'configure_integration',
  PHONE_NUMBER_ADDED: 'phone_number_added',
  BASIC_COMPANY_INFO_COMPLETED: 'basic_company_info_completed',
  DOWNLOAD_BANK_FILE_V2: 'download_bank_file_v2',
  DOWNLOAD_EXOGENA_FILE: 'download_exogena_file',
  DOWNLOAD_PAYSLIP_FILE_V2: 'download_payslip_file_v2',
  DOWNLOAD_ACCOUNTING_FILE_V2: 'download_accounting_file_v2',
  DOWNLOAD_PAYROLL_SUMMARY_FILE_V2: 'download_payroll_summary_file_v2',
  ISSUE_ELECTRONIC_PAYROLL_V2: 'issue_electronic_payroll_v2',
  SEND_PAYSLIPS_V2: 'send_payslips_v2',
  AUTHORIZE_ELECTRONIC_PAYROLL_CONFIGURATION_V2:
    'authorize_electronic_payroll_configuration_v2',
  PROCESS_PAYROLL_V2: 'process_payroll_v2',
  PROCESS_SOCIAL_BENEFIT_V2: 'process_social_benefit_v2',
  TERMINATE_WORKER_V2: 'terminate_worker_v2',
  UPDATE_BILLING_INFORMATION_V2: 'update_billing_information_v2',
  UPDATE_PAYMENT_METHOD_V2: 'update_payment_method_v2',
  CHANGE_SUBSCRIPTION_V2: 'change_subscription_v2',
  DOWNLOAD_INCOME_CERTIFICATE_FILE_V2: 'download_income_certificate_file_v2',
  DOWNLOAD_FOLDER_DOCUMENT_FILE_V2: 'download_folder_document_file_v2',
  DOWNLOAD_LABOR_CERTIFICATE_FILE_V2: 'download_labor_certificate_file_v2',
  CREATE_NOVELTY_REQUEST_V2: 'create_novelty_request_v2',
  DOWNLOAD_WORKER_PAYMENTS_HISTORIES_V2:
    'download_worker_payments_histories_v2',
  VIEW_PAYROLLS_DETAIL_V2: 'view_payrolls_detail_v2',
  EDIT_WORKER_PROFILE_DATA_V2: 'edit_worker_profile_data_v2',
  NOVELTY_SELF_MANAGEMENT: 'novelty_self_management',
  ADDED_COMPANY_FROM_PARTNERS: 'added_company_from_partners',
  SELECT_COMPANY_FROM_PARTNERS: 'select_company_from_partners',
  ADDED_ADMIN: 'added_admin',
  ADDED_ACCOUNTANT: 'added_accountant',
  ADDED_BASIC_COMPANY_DATA: 'added_basic_company_data',
  ADDED_NEW_EMPLOYEE: 'added_new_employee',
  ADDED_NOVELTIES: 'added_novelties',
  ADDED_OVERTIME_AND_SURCHARGES: 'added_overtime_and_surcharges',
  COMPANY_DOWNLOADED_SOCIAL_SECURITY_FILE:
    'company_downloaded_social_security_file',
  COMPANY_UPLOADED_PROVIDER_CREDENTIALS:
    'company_uploaded_provider_credentials',
  COMPANY_UPLOADED_IDENTIFICATION_DOCUMENT:
    'company_uploaded_identification_document',
  COMPANY_UPLOADED_LEGAL_REPRESENTATIVE_DOCUMENT:
    'company_uploaded_legal_representative_document',
  COMPANY_UPLOADED_TAX_DOCUMENT: 'company_uploaded_tax_document',
  SAVED_ACCOUNTING_CHANGES: 'saved_accounting_changes',
  STARTED_FREE_TRIAL: 'started_free_trial',
  COMPANY_LOCATION_ADD: 'company_location_add',
  DOWNLOAD_NOVELTIES_REPORT_FILE: 'download_novelties_report_file',
  EMPLOYEE_REGISTER_UPLOAD: 'employee_register_upload',
  RECURRENT_INCOME_ADD: 'recurrent_income_add',
  RECURRENT_DEDUCTION_ADD: 'recurrent_deduction_add',
  COMPANY_UPDATE_LOGO: 'company_update_logo',
  VISIT_SETTINGS_HISTORY_LOGS: 'visit_settings_history_logs',
  NOVELTIES_FILE_UPLOAD: 'novelties_file_upload',
  PAYROLL_BONUS_ADD: 'payroll_bonus_add',
  PAYROLL_LOAN_OTHER_DEDUCTION_ADD: 'payroll_loan_other_deduction_add',
}
