import { Link } from 'react-router-dom'

import { Box, ButtonBase, Chip, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import {
  STEPS_STATUSES,
  STEPS_STATUSES_LABEL,
  getStepStatusColors,
} from './helpers'

export function StepCard({
  status,
  stepNumber,
  title,
  description,
  path,
  action,
}) {
  return (
    <ButtonBase
      disabled={
        STEPS_STATUSES.pending === status ||
        (STEPS_STATUSES.completed === status && path)
      }
      component={path ? Link : Box}
      to={path}
      onClick={action}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.white.main,
          borderRadius: '1rem',
          width: '100%',
          display: 'grid',
          paddingX: theme.spacing(3),
          paddingY: theme.spacing(2),
          gridTemplateColumns: '2.1875rem 1fr 2.5rem',
          alignItems: 'center',
          columnGap: theme.spacing(2.5),
          ...(status === STEPS_STATUSES.active && {
            outline: `1px solid ${theme.palette.primary.main}`,
          }),
          ...(status === STEPS_STATUSES.completed && {
            outline: `2px solid ${theme.palette.primary.dark}`,
          }),
        })}
      >
        <Typography
          variant="h1"
          sx={(theme) => ({
            color: theme.palette.black.light,
            ...(status === STEPS_STATUSES.completed && {
              color: theme.palette.primary.dark,
            }),
            ...(status === STEPS_STATUSES.active && {
              color: theme.palette.primary.main,
            }),
          })}
        >
          {stepNumber}
        </Typography>
        <Box
          sx={({ spacing, breakpoints }) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'left',
            gap: spacing(1),
            ...(status !== STEPS_STATUSES.completed && {
              [breakpoints.up('tablet')]: {
                gap: spacing(0.75),
              },
            }),
            [breakpoints.down('tablet')]: {
              textAlign: 'center',
            },
          })}
        >
          <Box
            sx={({ spacing, breakpoints }) => ({
              display: 'flex',
              alignItems: 'center',
              gap: spacing(1.5),
              [breakpoints.down('tablet')]: {
                gap: spacing(1),
                flexDirection: 'column',
                alignItems: 'center',
              },
            })}
          >
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
            {status !== STEPS_STATUSES.completed ? (
              <Chip
                sx={(theme) => {
                  const statusColors = getStepStatusColors(theme)[status]

                  return {
                    backgroundColor: statusColors?.backgroundColor,
                    color: statusColors?.color,
                    padding: theme.spacing(1.5, 2),
                    '& > span': {
                      fontSize: '0.75rem',
                    },
                  }
                }}
                label={STEPS_STATUSES_LABEL[status]}
                size="small"
              />
            ) : null}
          </Box>
          <Typography variant="body1" color="black.dark">
            {description}
          </Typography>
        </Box>
        {status === STEPS_STATUSES.active ? (
          <Icon
            name="chevron-right"
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            fontSize="large"
          />
        ) : null}
        {status === STEPS_STATUSES.completed ? (
          <Box
            sx={({ palette }) => ({
              width: '2.5rem',
              height: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: palette.primary.dark,
            })}
          >
            <Icon
              name="check"
              sx={(theme) => ({
                color: theme.palette.white.main,
              })}
              fontSize="medium"
            />
          </Box>
        ) : null}
      </Box>
    </ButtonBase>
  )
}
