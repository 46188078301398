export const getStepsStatuses = (firstSteps) => {
  const companyDataStatus = firstSteps.company_data_configured
    ? 'completed'
    : 'active'

  const firstWorkerStatus = firstSteps.first_worker
    ? 'completed'
    : companyDataStatus === 'completed'
      ? 'active'
      : 'pending'

  const firstPayrollStatus = firstSteps.first_payroll
    ? 'completed'
    : firstWorkerStatus === 'completed' && companyDataStatus === 'completed'
      ? 'active'
      : 'pending'

  return {
    companyDataStatus,
    firstWorkerStatus,
    firstPayrollStatus,
  }
}

export const STEPS_STATUSES = {
  completed: 'completed',
  active: 'active',
  pending: 'pending',
}

export const STEPS_STATUSES_LABEL = {
  active: 'En proceso',
  pending: 'Sin completar',
}

export const getStepStatusColors = (theme) => ({
  active: {
    backgroundColor: theme.palette.primary[200],
    color: theme.palette.primary.dark,
  },
  pending: {
    backgroundColor: theme.palette.white.light,
    color: theme.palette.black.light,
  },
})
